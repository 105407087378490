@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

body {
  font-family: "Source Sans 3", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
}

.header .logo {
  height: 50px;
}

.header nav ul {
  list-style: none;
  display: flex;
  gap: 15px;
  margin: 0;
}

.header nav ul li {
  margin: 0;
}

.header nav ul li a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.header .hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.header .hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px 0;
}

.nav {
  display: flex;
  justify-content: flex-end;
}

.nav ul {
  display: flex;
  gap: 15px;
}

@media (max-width: 768px) {
  .nav {
    display: none;
  }

  .nav.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: #333;
    padding: 10px;
    border-radius: 5px;
  }

  .nav ul {
    flex-direction: column;
    gap: 10px;
  }

  .header .hamburger {
    display: flex;
  }
}

.footer {
  text-align: center;
  padding: 20px;
  background-color: #333;
  color: #fff;
}

.main {
  padding: 20px;
}

.home, .pricing, .photo-orders, .photographer, .portfolio, .blog, .contact {
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

h2 {
  font-size: 1.5em;
  margin-top: 20px;
}

p {
  font-size: 1em;
  line-height: 1.5;
}

.photographer-content {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.photographer-image {
  max-width: 300px;
  border-radius: 10px;
}

.photographer-text {
  max-width: 600px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-form label {
  font-weight: bold;
}

.contact-form input,
.contact-form textarea {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.contact-form input[type="submit"] {
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}

.contact-form input[type="submit"]:hover {
  background-color: #555;
}

.contact-message {
  margin-top: 20px;
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
}
