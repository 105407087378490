.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Asetetaan korkeus koko näytön korkeudeksi */
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  position: relative;
  max-width: 600px; /* Maksimileveys, jonka modal voi saavuttaa */
  width: 90%; /* Modalin leveys on 90% containerinsa leveydestä */
  margin: auto; /* Keskittää elementin automaattisesti */
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

.modal img {
  max-width: 100%;
  height: auto; /* Säilyttää kuvan alkuperäiset mittasuhteet */
}

.modal button {
  position: absolute;
  top: 20px;
  right: 20px; /* Nappi sijoitetaan 20px päähän oikeasta yläkulmasta */
}

.modal .body {
  margin-bottom: 20px; /* Tilaa modaalisen ikkunan sisällön ja footerin välille */
}

.modal .footer {
  text-align: right; /* Asettaa sulje-napin oikealle puolelle */
}
